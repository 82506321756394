import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

// mui
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import StoreIcon from "@material-ui/icons/Store";
import FavoriteIcon from "@material-ui/icons/Favorite";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

// custom
import Page from "components/Page";
import Table from "components/Table";

import { formattedPrice } from "helpers/stripe";

// styles
import styles from "./styles";

class WrapperPaymentsPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    bazaarCustomer: PropTypes.object,
    subscriptions: PropTypes.array,
    loading: PropTypes.bool,
  };

  render() {
    const { subscriptions, bazaarCustomer, loading } = this.props;

    const subscription = subscriptions.find(
      (s) => s.id === bazaarCustomer.subscriptionID
    );
    console.log(bazaarCustomer.payments);

    return (
      <Page loading={loading} helmet="Payments">
        <Grid
          container
          style={{ maxWidth: 900, marginLeft: "auto", marginRight: "auto" }}
          justify="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <Paper
              elevation={8}
              style={{
                background: subscription && subscription.color,
                color: "white",
                width: "100%",
                maxWidth: 300,
                height: 300,
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 50,
              }}
            >
              {subscription ? (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={subscription && subscription.icon}
                    height="80"
                    alt="subscription"
                  />
                  <Typography variant="h4" color="inherit">
                    {subscription && subscription.name}
                  </Typography>
                  <Typography variant="body2" color="inherit">
                    This is your current membership
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() =>
                      window.open(
                        "https://recording-musicians-association-los-angeles.square.site/#vRhlQP"
                      )
                    }
                    style={{ marginTop: 20 }}
                  >
                    Renew
                  </Button>
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <img
                    src="https://storage.googleapis.com/rma-la/media/no-membership.png"
                    height="80"
                    alt="subscription"
                  />
                  <Typography variant="h4">No Membership</Typography>
                  <Typography variant="body2">
                    You don't have a current membership
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() =>
                      window.open(
                        "https://recording-musicians-association-los-angeles.square.site/#vRhlQP"
                      )
                    }
                    style={{ marginTop: 20 }}
                  >
                    Buy
                  </Button>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              onClick={() =>
                window.open(
                  "https://recording-musicians-association-los-angeles.square.site/#vRhlQP"
                )
              }
              style={{
                boxShadow: "0 6px 28px 0 rgba(24,52,117,0.2)",
                padding: 12,
                cursor: "pointer",
              }}
            >
              <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item>
                  <CardMembershipIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h6">View all Memberships</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              onClick={() =>
                window.open(
                  "https://recording-musicians-association-los-angeles.square.site/#ZlQsMk"
                )
              }
              style={{
                boxShadow: "0 6px 28px 0 rgba(24,52,117,0.2)",
                padding: 12,
                cursor: "pointer",
              }}
            >
              <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item>
                  <MonetizationOnIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h6">Auction Item Payment</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              onClick={() =>
                window.open(
                  "https://recording-musicians-association-los-angeles.square.site/#eupJEv"
                )
              }
              style={{
                boxShadow: "0 6px 28px 0 rgba(24,52,117,0.2)",
                padding: 12,
                cursor: "pointer",
              }}
            >
              <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item>
                  <StoreIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h6">Online Ads</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              onClick={() =>
                window.open(
                  "https://recording-musicians-association-los-angeles.square.site/#VSmYAO"
                )
              }
              style={{
                boxShadow: "0 6px 28px 0 rgba(24,52,117,0.2)",
                padding: 12,
                cursor: "pointer",
              }}
            >
              <Grid container justify="center" alignItems="center" spacing={1}>
                <Grid item>
                  <FavoriteIcon />
                </Grid>
                <Grid item>
                  <Typography variant="h6">Donations</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {bazaarCustomer.payments && bazaarCustomer.payments.length ? (
            <Grid item xs={12}>
              <Table
                data={bazaarCustomer.payments}
                noHeader
                meta={[
                  {
                    path: "paymentDate",
                    title: "Date",
                    numeric: false,
                    transform: (t) => moment(t).format("lll"),
                  },
                  {
                    path: "total",
                    title: "Amount",
                    numeric: false,
                    transform: (t) => `$${formattedPrice(t)}`,
                  },
                ]}
                title={"Payments History"}
                // onRowSelect={this.onSelect.bind(this)}
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperPaymentsPage);
