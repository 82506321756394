export default (theme) => ({
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
  memberChip: {
    borderColor: "#2196f3",
    color: "#2196f3",
  },
});
