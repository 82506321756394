import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "local420";
const tags = `${REACT_APP_API_PREFIX}/${service}/tags`;

export function getTags(filters) {
  const url = `${tags}${filters ? `?filters=${JSON.stringify(filters)}` : ""}`;
  return {
    method: GET,
    url,
  };
}

export function getTagById(id) {
  const url = `${tags}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateTag(id, body) {
  const url = `${tags}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteTag(id) {
  const url = `${tags}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createTag(body) {
  const url = `${tags}`;
  return {
    method: POST,
    url,
    body,
  };
}
