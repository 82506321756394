import { COMPANIES } from "constants/routes";
import { checkAccess } from "helpers/auth";
import { serviceRoles } from "config/access";
import CompaniesPage from "./container/CompaniesPage";

export default function getRoute(userAuth) {
  const { account, local420 } = serviceRoles;
  const requireAuth = {
    [local420.name]: [
      local420.roles.user,
      local420.roles.admin,
      local420.roles.anon,
    ],
    [account.name]: [account.roles.superadmin, account.roles.anon],
  };
  return {
    path: COMPANIES,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: CompaniesPage,
  };
}
