export default (theme) => ({
  validated: {
    border: "solid 1px #4caf50",
    color: "#4caf50",
    padding: "2px 4px",
    borderRadius: 20,
    marginLeft: 4,
    fontSize: 10,
  },
  primary: {
    border: "solid 1px #9e9e9e",
    color: "#9e9e9e",
    padding: "2px 4px",
    borderRadius: 20,
    marginLeft: 4,
    fontSize: 10,
  },
  private: {
    border: "solid 1px #f44336",
    color: "#f44336",
    padding: "2px 4px",
    borderRadius: 20,
    marginLeft: 4,
    fontSize: 10,
  },
  public: {
    border: "solid 1px #2196f3",
    color: "#2196f3",
    padding: "2px 4px",
    borderRadius: 20,
    marginLeft: 4,
    fontSize: 10,
  },
  bio: {
    borderRadius: 4,
    flexWrap: "wrap",
    fontSize: 14,
    textAlign: "left",
    color: theme.palette.type === "dark" ? "#e0e0e0" : "#37474f",
    background: theme.palette.type === "dark" ? "#424242" : "#F1F5F9",
    padding: 16,
    marginTop: 16,
  },
  paper: {
    margin: "auto",
    boxShadow: "0 7px 14px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07)",
    padding: 16,
    marginTop: 16,
    width: "calc(100% - 32px)",
    borderRadius: 12,
    background: theme.palette.type === "dark" ? "#212121" : "white",
  },
  inputBase: {
    borderBottom: "solid 1px rgba(155,155,155,0.3)",
    padding: "0px 0px 0px 0px",
  },
  avatarOverlay: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "absolute",
    overflow: "hidden",
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    top: -8,
    left: -8,
    width: "calc(100% + 16px)",
    height: "calc(100% - 16px)",
  },
  avatarBlurredOverlay: {
    backdropFilter: "blur(20px)",
    position: "absolute",
    overflow: "hidden",
    top: -8,
    left: -8,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    width: "calc(100% + 16px)",
    height: "calc(100% - 16px)",
  },
  avatar: {
    width: 150,
    height: 150,
    margin: "auto",
    border: "solid 4px white",
    boxShadow: "0 7px 14px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07)",
  },
  memberChip: {
    borderColor: "#2196f3",
    color: "#2196f3",
  },
});
