import React from "react";
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import Person from "@material-ui/icons/Person";
import Music from "@material-ui/icons/MusicNote";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";

// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function SearchBox({ onSearch, instruments }) {
  const [name, setName] = React.useState("");
  const [instrument, setInstrument] = React.useState("");
  const classes = useStyles(styles);

  return (
    <div className={classes.searchBox}>
      <Typography variant="h6" style={{ color: "white" }}>
        Start Searching Here
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs={12} md={5}>
          <Typography color="inherit">Person Name</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper
            component="form"
            style={{
              padding: "2px 4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton style={{ padding: 10 }} aria-label="menu">
              <Person />
            </IconButton>
            <InputBase
              fullWidth
              value={name}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  e.preventDefault();
                  onSearch(name, instrument);
                }
              }}
              onChange={(e) => setName(e.target.value)}
              placeholder="First, Last or Nickname"
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container alignItems="center" style={{ paddingTop: 8 }}>
        <Grid item xs={12} md={5}>
          <Typography color="inherit">Instrument</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper
            component="form"
            style={{
              padding: "2px 4px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton style={{ padding: 10 }} aria-label="menu">
              <Music />
            </IconButton>
            <Autocomplete
              id="combo-box-demo"
              options={instruments}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              onChange={(e, v) => setInstrument(v)}
              renderInput={(params) => (
                <TextField {...params} placeholder="Instrument" />
              )}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid container alignItems="center" style={{ paddingTop: 8 }}>
        <Grid item xs={12} md={5} />
        <Grid item xs={12} md={7}>
          <Button
            variant="contained"
            color="primary"
            style={{ width: "100%" }}
            onClick={() => onSearch(name, instrument)}
          >
            Search Members
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
