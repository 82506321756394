// routes
import {
  PROFILE,
  MY_ACCOUNT,
  PAYMENTS,
  COMPANIES,
  MEMBERS,
} from "constants/routes";

import People from "@material-ui/icons/PeopleTwoTone";
import Person from "@material-ui/icons/PersonTwoTone";
import Settings from "@material-ui/icons/SettingsTwoTone";
import AttachMoney from "@material-ui/icons/AttachMoneyTwoTone";
import BusinessIcon from "@material-ui/icons/BusinessTwoTone";

export const menu = [
  {
    icon: Person,
    label: "My Profile",
    path: PROFILE,
    divider: true,
  },
  {
    icon: AttachMoney,
    label: "Payments",
    path: PAYMENTS,
    divider: true,
  },
  {
    icon: People,
    label: "Directory",
    path: MEMBERS,
    divider: true,
  },
  {
    icon: BusinessIcon,
    label: "Resources",
    path: COMPANIES,
    divider: true,
  },
  {
    icon: Settings,
    label: "Settings",
    path: MY_ACCOUNT,
    divider: true,
  },
];
