export const ROOT = "/";
export const SIGNIN = "/signin";
export const MY_ACCOUNT = "/my-account";
export const ROUTE404 = "/404";
export const MEMBERS = "/members";
export const COMPANIES = "/resources";
export const COMPANY = "/company";
export const PROFILE = "/profile";
export const PAYMENTS = "/payments";
export const MEMBER_TAGS = "/memberTags";
