import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "local420";
const members = `${REACT_APP_API_PREFIX}/${service}/members`;

export function getMembers(filters) {
  const url = `${members}/search${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getMemberById(id) {
  const url = `${members}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function inviteMember(id) {
  const url = `${members}/${id}/invite`;
  return {
    method: GET,
    url,
  };
}

export function updateMember(id, body) {
  const url = `${members}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMember(id) {
  const url = `${members}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMember(body) {
  const url = `${members}`;
  return {
    method: POST,
    url,
    body,
  };
}
