import React, { Component } from "react";
import PropTypes from "prop-types";

// material-ui
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Business from "@material-ui/icons/Business";
import Chip from "@material-ui/core/Chip";
import VisibilitySensor from "react-visibility-sensor";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";

// routes
import { COMPANY } from "constants/routes";

// styles
import styles from "./styles";

const Company = (props) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <VisibilitySensor
      onChange={(isVisible) => setVisible(isVisible)}
      partialVisibility
    >
      <Grid
        item
        xs={12}
        md={4}
        lg={3}
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        <Paper
          style={{
            padding: 24,
            width: "calc(100% - 48px)",
            height: "calc(100% - 48px)",
            minHeight: 170,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {visible ? (
            <Grid
              container
              alignItems="stretch"
              spacing={2}
              direction="column"
              justify="space-between"
              style={{
                flex: 1,
              }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Avatar
                    src={props.m.avatar}
                    style={{ width: 100, height: 100 }}
                  >
                    <Business style={{ fontSize: 44 }} />
                  </Avatar>
                </Grid>
                <Grid xs={6} item>
                  <Grid
                    container
                    direction="column"
                    style={{
                      height: "100%",
                    }}
                    justify="space-between"
                  >
                    <Grid item>
                      <Typography variant="body2" style={{ fontSize: 18 }}>
                        <b>{`${props.m.companyName}`}</b>
                      </Typography>
                      <Grid container spacing={1} style={{ marginTop: 4 }}>
                        {props.m.tags === undefined || props.m.tags === null ? (
                          <Chip
                            size="small"
                            label="No Tags"
                            variant="outlined"
                          />
                        ) : (
                          []
                        )}
                        {props.m.tags &&
                          props.m.tags.map((i) => (
                            <Grid item key={i.id}>
                              <Chip size="small" label={i.name} />
                            </Grid>
                          ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  color="primary"
                  variant="contained"
                  size="small"
                  style={{
                    width: "100%",
                    textTransform: "none",
                  }}
                  onClick={() =>
                    props.history.push(`${COMPANY}?id=${props.m.id}`)
                  }
                >
                  View Profile
                </Button>
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </Paper>
      </Grid>
    </VisibilitySensor>
  );
};

class MembersList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    members: PropTypes.array,
    onSelect: PropTypes.func,
    subscriptions: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = {
    max: 40,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.max === this.state.max) {
      return true;
    }
    return false;
  }

  onSelect(selectedMember) {
    const { onSelect } = this.props;
    this.setState({ selectedMember });
    if (onSelect) {
      onSelect(selectedMember);
    }
  }

  render() {
    const { members, history, classes, subscriptions } = this.props;
    const { max } = this.state;

    return (
      <div
        key={max}
        style={{
          maxWidth: 1400,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          alignContent="stretch"
          spacing={4}
        >
          {members.slice(0, max).map((m) => (
            <Company
              key={m.id}
              history={history}
              classes={classes}
              subscriptions={subscriptions}
              m={m}
            />
          ))}
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {max < members.length ? (
              <VisibilitySensor
                onChange={() => {
                  this.setState({
                    max: max + 40,
                  });
                }}
              >
                <CircularProgress />
              </VisibilitySensor>
            ) : (
              []
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(MembersList);
