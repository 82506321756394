import { DATA } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "unity";
const uploads = `${REACT_APP_API_PREFIX}/${service}/uploads`;

export function upload(file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${uploads}`;

  return {
    method: DATA,
    url,
    body: data,
  };
}
