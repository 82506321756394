import { GET_UNIONS } from "constants/unions";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_UNIONS}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
