import React from "react";
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
import Tooltip from "@material-ui/core/Tooltip";

// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Subscription({ subscription }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  if (subscription) {
    return (
      <Grow in>
        <Tooltip title="This is your current Membership">
          <div
            style={{
              color: "white",
              cursor: "pointer",
              border: `solid 2px white`,
              background: subscription.color,
              padding: "2px 6px",
              borderRadius: 70,
              display: "flex",
              alignItems: "center",
              fontSize: 14,
            }}
          >
            {subscription && subscription.icon ? (
              <img
                src={subscription.icon}
                height="25"
                style={{ paddingRight: 4 }}
              />
            ) : (
              []
            )}
            {subscription.name}
          </div>
        </Tooltip>
      </Grow>
    );
  }

  return <div />;
}
