import { COMPANY } from "constants/routes";
import { checkAccess } from "helpers/auth";
import { serviceRoles } from "config/access";
import CompanyPage from "./container/CompanyPage";

export default function getSettingsRoute(userAuth) {
  const { account, local420 } = serviceRoles;
  const requireAuth = {
    [local420.name]: [
      local420.roles.user,
      local420.roles.admin,
      local420.roles.anon,
    ],
    [account.name]: [account.roles.superadmin, account.roles.anon],
  };

  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: COMPANY,
    withSidebar: true,
    withAppBar: true,
    component: CompanyPage,
  };
}
