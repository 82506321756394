import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperMemberTagsPage from "../component/WrapperMemberTagsPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  memberTags: state.memberTags,
});

const mapDispatchToProps = (dispatch) => ({
  getMemberTags: () => dispatch(Actions.getMemberTags()),
  getMemberTagById: (...args) => dispatch(Actions.getMemberTagById(...args)),
  createMemberTag: (...args) => dispatch(Actions.createMemberTag(...args)),
  deleteMemberTag: (...args) => dispatch(Actions.deleteMemberTag(...args)),
  updateMemberTag: (...args) => dispatch(Actions.updateMemberTag(...args)),
});
class MemberTagsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    memberTags: PropTypes.array,
    getMemberTags: PropTypes.func,
    getMemberTagById: PropTypes.func,
    createMemberTag: PropTypes.func,
    deleteMemberTag: PropTypes.func,
    updateMemberTag: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, memberTags } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: memberTags.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getMemberTags } = this.props;
    await Promise.all([getMemberTags()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      memberTags,
      getMemberTagById,
      createMemberTag,
      deleteMemberTag,
      updateMemberTag,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperMemberTagsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        memberTags={memberTags}
        getMemberTagById={getMemberTagById}
        createMemberTag={createMemberTag}
        deleteMemberTag={deleteMemberTag}
        updateMemberTag={updateMemberTag}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberTagsPage);
