import { networkAction } from "helpers/network/networkAction";

import {
  GET_NOTES,
  GET_NOTE_BY_ID,
  DELETE_NOTE,
  UPDATE_NOTE,
  CREATE_NOTE,
} from "constants/notes";

import * as Api from "api";

export const getNotes = () => async (dispatch) =>
  networkAction(dispatch, GET_NOTES, Api.getNotes, []);

export const getNoteById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_NOTE_BY_ID, Api.getNoteById, [id]);

export const deleteNote = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_NOTE, Api.deleteNote, [id]);

export const createNote = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_NOTE, Api.createNote, [body]);

export const updateNote = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_NOTE, Api.updateNote, [id, body]);
