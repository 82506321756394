import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "local420";
const memberTags = `${REACT_APP_API_PREFIX}/${service}/memberTags`;

export function getMemberTags(filters) {
  const url = `${memberTags}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getMemberTagById(id) {
  const url = `${memberTags}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateMemberTag(id, body) {
  const url = `${memberTags}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMemberTag(id) {
  const url = `${memberTags}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMemberTag(body) {
  const url = `${memberTags}`;
  return {
    method: POST,
    url,
    body,
  };
}
