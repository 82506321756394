import * as Api from "api";
import {
  CREATE_MEMBER,
  DELETE_MEMBER,
  GET_MEMBERS,
  GET_MEMBER_BY_ID,
  INVITE_MEMBER,
  UPDATE_MEMBER,
} from "constants/members";
import { networkAction } from "helpers/network/networkAction";

export const getMembers = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_MEMBERS, Api.getMembers, [filters]);

export const getMemberById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MEMBER_BY_ID, Api.getMemberById, [id]);

export const inviteMember = (id) => async (dispatch) =>
  networkAction(dispatch, INVITE_MEMBER, Api.inviteMember, [id]);

export const deleteMember = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MEMBER, Api.deleteMember, [id]);

export const createMember = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MEMBER, Api.createMember, [body]);

export const updateMember = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MEMBER, Api.updateMember, [id, body]);
