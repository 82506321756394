import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "local420";
const memberInstruments = `${REACT_APP_API_PREFIX}/${service}/memberInstruments`;

export function getMemberInstruments(filters) {
  const url = `${memberInstruments}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getMemberInstrumentById(id) {
  const url = `${memberInstruments}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateMemberInstrument(id, body) {
  const url = `${memberInstruments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMemberInstrument(id) {
  const url = `${memberInstruments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMemberInstrument(body) {
  const url = `${memberInstruments}`;
  return {
    method: POST,
    url,
    body,
  };
}
