import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "local420";
const contacts = `${REACT_APP_API_PREFIX}/${service}/contacts`;

export function getContacts(filters) {
  const url = `${contacts}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getContactById(id) {
  const url = `${contacts}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateContact(id, body) {
  const url = `${contacts}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteContact(id) {
  const url = `${contacts}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createContact(body) {
  const url = `${contacts}`;
  return {
    method: POST,
    url,
    body,
  };
}
