function isEmpty(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
}

export function checkAccess(requiredLevel, currentLevel) {
  // check if route is anon
  let anon = false;
  for (const k in requiredLevel) {
    if (requiredLevel.hasOwnProperty(k)) {
      const levels = requiredLevel[k];
      for (const l in levels) {
        if (levels.hasOwnProperty(l)) {
          const level = levels[l];
          if (level === "anon") {
            anon = true;
          }
        }
      }
    }
  }

  if (anon) {
    return true;
  }

  if (isEmpty(requiredLevel)) {
    return true;
  }

  if (isEmpty(currentLevel)) {
    return false;
  }

  let access = false;
  for (const service in requiredLevel) {
    if (requiredLevel.hasOwnProperty(service)) {
      const roles = requiredLevel[service];
      if (currentLevel[service]) {
        // user has an access in this service
        for (const k in roles) {
          if (roles.hasOwnProperty(k)) {
            const role = roles[k];
            if (role === "any") {
              access = true;
            }

            if (currentLevel[service].find((r) => r === role)) {
              // user has an explicit grant
              access = true;
            }
          }
        }
      }
    }
  }

  return access;
}
