export function formattedPrice(x) {
  let neg = false;
  if (x < 0) {
    neg = true;
  }

  const price = Math.abs(x / 100)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (neg) {
    return `-${price}`;
  }

  return `${price}`;
}
