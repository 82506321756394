import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "local420";
const memberUnions = `${REACT_APP_API_PREFIX}/${service}/memberUnions`;

export function getMemberUnions(filters) {
  const url = `${memberUnions}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getMemberUnionById(id) {
  const url = `${memberUnions}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateMemberUnion(id, body) {
  const url = `${memberUnions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMemberUnion(id) {
  const url = `${memberUnions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMemberUnion(body) {
  const url = `${memberUnions}`;
  return {
    method: POST,
    url,
    body,
  };
}
