import { networkAction } from "helpers/network/networkAction";

import {
  GET_MEMBER_TAGS,
  GET_MEMBER_TAG_BY_ID,
  DELETE_MEMBER_TAG,
  UPDATE_MEMBER_TAG,
  CREATE_MEMBER_TAG,
} from "constants/memberTags";

import * as Api from "api";

export const getMemberTags = () => async (dispatch) =>
  networkAction(dispatch, GET_MEMBER_TAGS, Api.getMemberTags, []);

export const getMemberTagById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MEMBER_TAG_BY_ID, Api.getMemberTagById, [id]);

export const deleteMemberTag = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MEMBER_TAG, Api.deleteMemberTag, [id]);

export const createMemberTag = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MEMBER_TAG, Api.createMemberTag, [body]);

export const updateMemberTag = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MEMBER_TAG, Api.updateMemberTag, [id, body]);
