import { GET_CURRENT_USER } from "constants/users";

import { SIGNIN } from "constants/sessions";

export default (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case `${GET_CURRENT_USER}_SUCCESS`:
    case `${SIGNIN}_SUCCESS`:
      return payload;
    default:
      return state;
  }
};
