import { networkAction } from "helpers/network/networkAction";

import {
  GET_NOTEBOOKS,
  GET_NOTEBOOK_BY_ID,
  DELETE_NOTEBOOK,
  UPDATE_NOTEBOOK,
  CREATE_NOTEBOOK,
} from "constants/notebooks";

import * as Api from "api";

export const getNotebooks = () => async (dispatch) =>
  networkAction(dispatch, GET_NOTEBOOKS, Api.getNotebooks, []);

export const getNotebookById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_NOTEBOOK_BY_ID, Api.getNotebookById, [id]);

export const deleteNotebook = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_NOTEBOOK, Api.deleteNotebook, [id]);

export const createNotebook = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_NOTEBOOK, Api.createNotebook, [body]);

export const updateNotebook = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_NOTEBOOK, Api.updateNotebook, [id, body]);
