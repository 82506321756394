import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// constants
import { COMPANY, PROFILE } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormMember extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,
    company: PropTypes.bool,

    member: PropTypes.object,

    createMember: PropTypes.func,
    updateMember: PropTypes.func,
    deleteMember: PropTypes.func,
    refresh: PropTypes.func,
    history: PropTypes.object,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      lastName: "",
      firstName: "",
      companyName: "",
      email: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.member);
    }
  }

  init(member) {
    this.setState({
      loading: false,
      firstName: member ? member.firstName : "",
      lastName: member ? member.lastName : "",
      companyName: member ? member.companyName : "",
      email: member ? member.email : "",
    });
  }

  handleChange = (name) => (event) => {
    const value = event.target.value;

    this.setState({
      [name]: value,
    });
  };

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: "If you delete this member, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteMember, close, refresh, member } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteMember(member.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Member has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateMember,
      createMember,
      member,
      refresh,
      close,
      history,
      company,
    } = this.props;

    const { firstName, lastName, companyName, email } = this.state;

    const { NotificationCenter } = this.context;

    let resp = undefined;
    this.setState({ loading: true });
    if (member) {
      resp = await updateMember(member.id, {
        firstName,
        lastName,
        companyName,
        email,
        company,
      });
    } else {
      resp = await createMember({
        firstName,
        lastName,
        companyName,
        email,
        company,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: member ? "Member has been updated." : "Member has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      close();
      setTimeout(NotificationCenter.hide, 1500);
      history.push(`${company ? COMPANY : PROFILE}?id=${resp.payload.id}`);
    }
  }

  render() {
    const { classes, fullScreen, open, member, close, company } = this.props;

    const { lastName, firstName, loading, companyName, email } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">Loading</DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {member
                ? `${member.name}`
                : `Let\'s Create a New ${company ? "Resource" : "Member"}`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {member
                  ? `To update the ${
                      company ? "Resource" : "Member"
                    }, fill the form below :`
                  : `To create a new ${
                      company ? "Resource" : "Member"
                    }, fill the form below :`}
              </DialogContentText>
              <Grid container spacing={24}>
                {company ? (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        id="companyName"
                        label="Company Name"
                        className={classes.textField}
                        value={companyName}
                        helperText="Required"
                        onChange={this.handleChange("companyName")}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        id="firstName"
                        label="First Name"
                        className={classes.textField}
                        value={firstName}
                        helperText="Required"
                        onChange={this.handleChange("firstName")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="lastName"
                        label="Last Name"
                        className={classes.textField}
                        value={lastName}
                        helperText="Required"
                        onChange={this.handleChange("lastName")}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="Email"
                        label="Email"
                        className={classes.textField}
                        value={email}
                        onChange={this.handleChange("email")}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {member ? (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} /> Delete
                </Button>
              ) : (
                []
              )}
              <Button onClick={close}>
                <CloseIcon className={classes.leftIcon} /> Close
              </Button>
              <Button
                color="primary"
                autoFocus
                disabled={company ? !companyName : !(firstName && lastName)}
                onClick={this.save.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />{" "}
                {member ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormMember));
