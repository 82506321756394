import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "local420";
const unions = `${REACT_APP_API_PREFIX}/${service}/unions`;

export function getUnions(filters) {
  const url = `${unions}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getUnionById(id) {
  const url = `${unions}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateUnion(id, body) {
  const url = `${unions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteUnion(id) {
  const url = `${unions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createUnion(body) {
  const url = `${unions}`;
  return {
    method: POST,
    url,
    body,
  };
}
