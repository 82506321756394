export const routeNames = [
  "Signin",
  "MyAccount",
  "Route404",
  "Members",
  "Profile",
  "Payments",
  "Companies",
  "Company",
];
