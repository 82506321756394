import { MEMBER_TAGS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import MemberTagsPage from "./container/MemberTagsPage";

export default function getRoute(userAuth) {
  const requireAuth = {};
  return {
    path: MEMBER_TAGS,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: MemberTagsPage,
  };
}
