import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperCompanyPage from "../component/WrapperCompanyPage";

const mapStateToProps = (state) => ({
  user: state.user,
  tags: state.tags,
  unions: state.unions,
});

const mapDispatchToProps = (dispatch) => ({
  getMemberById: (...args) => dispatch(Actions.getMemberById(...args)),

  getInstruments: (...args) => dispatch(Actions.getInstruments(...args)),

  updateMember: (...args) => dispatch(Actions.updateMember(...args)),
  deleteMember: (...args) => dispatch(Actions.deleteMember(...args)),

  updateContact: (...args) => dispatch(Actions.updateContact(...args)),
  deleteContact: (...args) => dispatch(Actions.deleteContact(...args)),
  createContact: (...args) => dispatch(Actions.createContact(...args)),

  updateMemberInstrument: (...args) =>
    dispatch(Actions.updateMemberInstrument(...args)),
  deleteMemberInstrument: (...args) =>
    dispatch(Actions.deleteMemberInstrument(...args)),
  createMemberInstrument: (...args) =>
    dispatch(Actions.createMemberInstrument(...args)),

  getTags: (...args) => dispatch(Actions.getTags(...args)),
  getUnions: (...args) => dispatch(Actions.getUnions(...args)),
  getSubscriptions: (...args) => dispatch(Actions.getSubscriptions(...args)),

  createMemberTag: (...args) => dispatch(Actions.createMemberTag(...args)),
  deleteMemberTag: (...args) => dispatch(Actions.deleteMemberTag(...args)),

  createMemberUnion: (...args) => dispatch(Actions.createMemberUnion(...args)),
  deleteMemberUnion: (...args) => dispatch(Actions.deleteMemberUnion(...args)),
});
class CompanyPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    user: PropTypes.object,

    updateMember: PropTypes.func,

    getInstruments: PropTypes.func,

    updateContact: PropTypes.func,
    deleteContact: PropTypes.func,
    createContact: PropTypes.func,
    deleteMember: PropTypes.func,

    updateMemberInstrument: PropTypes.func,
    deleteMemberInstrument: PropTypes.func,
    createMemberInstrument: PropTypes.func,

    tags: PropTypes.array,
    unions: PropTypes.array,
    getTags: PropTypes.func,
    getUnions: PropTypes.func,

    createMemberTag: PropTypes.func,
    deleteMemberTag: PropTypes.func,

    createMemberUnion: PropTypes.func,
    deleteMemberUnion: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, getTags, getUnions, getSubscriptions } = this.props;
    const urlParams = getJsonFromUrl(location);
    this.state = {
      urlParams,
      memberID: urlParams.id || "me",
      loading: true,
    };

    getSubscriptions();
    getTags();
    getUnions();

    this.refresh();
  }

  async refresh() {
    const { getMemberById } = this.props;
    const { memberID } = this.state;

    const resp = await getMemberById(memberID);
    if (resp && resp.success) {
      this.setState({
        member: resp.payload,
        refreshKey: new Date().getTime(),
        loading: false,
      });
    }
  }

  render() {
    const {
      location,
      history,
      updateMember,
      getInstruments,
      updateContact,
      deleteContact,
      createContact,
      updateMemberInstrument,
      deleteMemberInstrument,
      createMemberInstrument,
      tags,
      unions,
      createMemberTag,
      deleteMemberTag,
      createMemberUnion,
      deleteMemberUnion,
      deleteMember,
      getTags,
      user,
    } = this.props;

    const { urlParams, refreshKey, member, loading } = this.state;

    const accountSuperadmin =
      user.services &&
      user.services.account &&
      user.services.account.findIndex((s) => s === "superadmin");
    const local420Admin =
      user.services &&
      user.services.local420 &&
      user.services.local420.findIndex((s) => s === "admin");

    return (
      <WrapperCompanyPage
        history={history}
        location={location}
        urlParams={urlParams}
        loading={loading}
        member={member}
        refreshKey={refreshKey}
        getInstruments={getInstruments}
        updateMember={updateMember}
        updateContact={updateContact}
        deleteContact={deleteContact}
        createContact={createContact}
        deleteMember={deleteMember}
        getTags={getTags}
        updateMemberInstrument={updateMemberInstrument}
        deleteMemberInstrument={deleteMemberInstrument}
        createMemberInstrument={createMemberInstrument}
        tags={tags}
        unions={unions}
        createMemberTag={createMemberTag}
        deleteMemberTag={deleteMemberTag}
        createMemberUnion={createMemberUnion}
        deleteMemberUnion={deleteMemberUnion}
        refresh={this.refresh.bind(this)}
        admin={accountSuperadmin >= 0 || local420Admin >= 0}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyPage);
