import { networkAction } from "helpers/network/networkAction";

import {
  GET_TAGS,
  GET_TAG_BY_ID,
  DELETE_TAG,
  UPDATE_TAG,
  CREATE_TAG,
} from "constants/tags";

import * as Api from "api";

export const getTags = () => async (dispatch) =>
  networkAction(dispatch, GET_TAGS, Api.getTags, []);

export const getTagById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_TAG_BY_ID, Api.getTagById, [id]);

export const deleteTag = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_TAG, Api.deleteTag, [id]);

export const createTag = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TAG, Api.createTag, [body]);

export const updateTag = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_TAG, Api.updateTag, [id, body]);
