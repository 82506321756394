import unions from "./unions";
import tags from "./tags";
import instruments from "./instruments";
import members from "./members";
import notebooks from "./notebooks";
import notes from "./notes";
import errors from "./errors";
import app from "./app";
import user from "./user";
import userApps from "./userApps";
import subscriptions from "./subscriptions";
import bazaarCustomer from "./bazaarCustomer";
import { combineReducers } from "redux"; //eslint-disable-line
export default combineReducers({
  errors,
  user,
  app,
  userApps,
  subscriptions,
  notes,
  notebooks,
  members,
  instruments,
  bazaarCustomer,
  tags,
  unions,
});
