import { REACT_APP_API, REACT_APP_ID } from "config";

export default class Fetcher {
  get(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "app-id": REACT_APP_ID,
      },
      method: "GET",
    });
  }

  post(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "app-id": REACT_APP_ID,
      },
      method: "POST",
    });
  }

  data(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: request.body,
      credentials: "include",
      headers: {
        "app-id": REACT_APP_ID,
      },
      method: "POST",
    });
  }

  delete(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "app-id": REACT_APP_ID,
      },
      method: "DELETE",
    });
  }

  put(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: "include",
      headers: {
        "content-type": "application/json",
        "app-id": REACT_APP_ID,
      },
      method: "PUT",
    });
  }
}
