import { networkAction } from "helpers/network/networkAction";

import {
  GET_MEMBER_INSTRUMENTS,
  GET_MEMBER_INSTRUMENT_BY_ID,
  DELETE_MEMBER_INSTRUMENT,
  UPDATE_MEMBER_INSTRUMENT,
  CREATE_MEMBER_INSTRUMENT,
} from "constants/memberInstruments";

import * as Api from "api";

export const getMemberInstruments = () => async (dispatch) =>
  networkAction(dispatch, GET_MEMBER_INSTRUMENTS, Api.getMemberInstruments, []);

export const getMemberInstrumentById = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_MEMBER_INSTRUMENT_BY_ID,
    Api.getMemberInstrumentById,
    [id]
  );

export const deleteMemberInstrument = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    DELETE_MEMBER_INSTRUMENT,
    Api.deleteMemberInstrument,
    [id]
  );

export const createMemberInstrument = (body) => async (dispatch) =>
  networkAction(
    dispatch,
    CREATE_MEMBER_INSTRUMENT,
    Api.createMemberInstrument,
    [body]
  );

export const updateMemberInstrument = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    UPDATE_MEMBER_INSTRUMENT,
    Api.updateMemberInstrument,
    [id, body]
  );
