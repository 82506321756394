import { networkAction } from "helpers/network/networkAction";

import {
  GET_UNIONS,
  GET_UNION_BY_ID,
  DELETE_UNION,
  UPDATE_UNION,
  CREATE_UNION,
} from "constants/unions";

import * as Api from "api";

export const getUnions = () => async (dispatch) =>
  networkAction(dispatch, GET_UNIONS, Api.getUnions, []);

export const getUnionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_UNION_BY_ID, Api.getUnionById, [id]);

export const deleteUnion = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_UNION, Api.deleteUnion, [id]);

export const createUnion = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_UNION, Api.createUnion, [body]);

export const updateUnion = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_UNION, Api.updateUnion, [id, body]);
