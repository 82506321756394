import { GET, PUT, DELETE, POST } from "constants/methods";

import { REACT_APP_API_PREFIX } from "config/env";

const service = "local420";
const instruments = `${REACT_APP_API_PREFIX}/${service}/instruments`;

export function getInstruments(filters) {
  const url = `${instruments}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentById(id) {
  const url = `${instruments}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateInstrument(id, body) {
  const url = `${instruments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInstrument(id) {
  const url = `${instruments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInstrument(body) {
  const url = `${instruments}`;
  return {
    method: POST,
    url,
    body,
  };
}
