export default (theme) => ({
  container: {
    padding: 5,
  },
  searchBox: {
    margin: 20,
    maxWidth: 600,
    background:
      theme.palette.type === "dark"
        ? "rgba(155,155,155, 0.2)"
        : "rgba(53,74,97, 0.6)",
    padding: 16,
    color: "white",
    borderRadius: 4,
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});
