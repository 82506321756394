import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperPaymentsPage from "../component/WrapperPaymentsPage";

const mapStateToProps = (state) => ({
  subscriptions: state.subscriptions,
  bazaarCustomer: state.bazaarCustomer,
});

const mapDispatchToProps = (dispatch) => ({
  getPayments: (...args) => dispatch(Actions.getPayments(...args)),
});
class PaymentsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    subscriptions: PropTypes.object,
    bazaarCustomer: PropTypes.object,
    getPayments: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: false,
    };
  }

  render() {
    const { location, history, subscriptions, bazaarCustomer } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperPaymentsPage
        history={history}
        location={location}
        subscriptions={subscriptions}
        bazaarCustomer={bazaarCustomer}
        loading={loading}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);
