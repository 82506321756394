import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperCompaniesPage from "../component/WrapperCompaniesPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  members: state.members,
  tags: state.tags,
  user: state.user,
  subscriptions: state.subscriptions,
});

const mapDispatchToProps = (dispatch) => ({
  getMembers: (...args) => dispatch(Actions.getMembers(...args)),
  getTags: (...args) => dispatch(Actions.getTags(...args)),
  getMemberById: (...args) => dispatch(Actions.getMemberById(...args)),
  createMember: (...args) => dispatch(Actions.createMember(...args)),
  deleteMember: (...args) => dispatch(Actions.deleteMember(...args)),
  updateMember: (...args) => dispatch(Actions.updateMember(...args)),
});
class CompaniesPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    members: PropTypes.array,
    getMembers: PropTypes.func,
    getMemberById: PropTypes.func,
    createMember: PropTypes.func,
    deleteMember: PropTypes.func,
    updateMember: PropTypes.func,

    getTags: PropTypes.func,
    tags: PropTypes.array,
    subscriptions: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { location, getTags } = this.props;
    getTags();
    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getMembers } = this.props;
    const filters = [];
    filters.push({
      name: "company",
      comparison: "eq",
      value: true,
    });
    await Promise.all([getMembers(filters)]);

    this.setState({ loading: false });
  }

  render() {
    const {
      members,
      getMemberById,
      createMember,
      deleteMember,
      updateMember,
      getMembers,
      history,
      tags,
      subscriptions,
      user,
    } = this.props;

    const { urlParams, loading } = this.state;

    const accountSuperadmin =
      user.services &&
      user.services.account &&
      user.services.account.findIndex((s) => s === "superadmin");
    const local420Admin =
      user.services &&
      user.services.local420 &&
      user.services.local420.findIndex((s) => s === "admin");

    return (
      <WrapperCompaniesPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        subscriptions={subscriptions}
        members={members}
        getMemberById={getMemberById}
        history={history}
        tags={tags}
        getMembers={getMembers}
        createMember={createMember}
        deleteMember={deleteMember}
        updateMember={updateMember}
        urlParams={urlParams}
        admin={accountSuperadmin >= 0 || local420Admin >= 0}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPage);
