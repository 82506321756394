import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccessibilityNewIcon from "@material-ui/icons/AccessibilityNew";
import Back from "@material-ui/icons/ArrowBackIos";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import LinkIcon from "@material-ui/icons/Link";
import MailIcon from "@material-ui/icons/Mail";
import MusicNote from "@material-ui/icons/MusicNote";
import NavigationIcon from "@material-ui/icons/Navigation";
import PhoneIcon from "@material-ui/icons/Phone";
import SmartphoneIcon from "@material-ui/icons/Smartphone";
import TextsmsIcon from "@material-ui/icons/Textsms";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Skeleton from "@material-ui/lab/Skeleton";
import AvatarScale from "components/AvatarScale";
// custom
import Page from "components/Page";
// config
import { REACT_APP_CLIENT_NAME } from "config";
import { COMPANIES } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const Tag = ({ a, admin, classes }) => (
  <Typography variant="overline" color="textSecondary">
    {a.label}
    {admin ? (
      <React.Fragment>
        {a.public ? (
          <Tooltip title="Everybody can see">
            <span className={classes.public}>Public</span>
          </Tooltip>
        ) : (
          <Tooltip
            title={`Only you and ${REACT_APP_CLIENT_NAME} staff can see`}
          >
            <span className={classes.private}>Private</span>
          </Tooltip>
        )}
      </React.Fragment>
    ) : (
      []
    )}
    {a.primary ? <span className={classes.primary}>Primary</span> : []}
    {a.validated ? <span className={classes.validated}>Validated</span> : []}
  </Typography>
);

const TagSelect = ({ getTags, onSelect }) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await getTags();
      const intruments = response.payload;
      const opt = [];
      for (const key in intruments) {
        if (intruments.hasOwnProperty(key)) {
          const element = intruments[key];
          opt.push({
            name: element.name,
            value: element,
          });
        }
      }
      if (active) {
        setOptions(opt);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      open={open}
      fullWidth
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      onChange={(event, newValue) => {
        onSelect(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Add a Tag"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

const ContactEdit = ({
  classes,
  contact,
  updateContact,
  deleteContact,
  refresh,
}) => {
  const { category } = contact;
  const [loading, setLoading] = React.useState(false);
  const [label, setLabel] = React.useState(contact.label || "");
  const [primary, setPrimary] = React.useState(contact.primary || "");
  const [pub, setPub] = React.useState(contact.public || "");
  const [street, setStreet] = React.useState(contact.street || "");
  const [zipcode, setZipcode] = React.useState(contact.zipcode || "");
  const [city, setCity] = React.useState(contact.city || "");
  const [state, setState] = React.useState(contact.state || "");
  const [country, setCountry] = React.useState(contact.country || "");
  const [email, setEmail] = React.useState(contact.email || "");
  const [phone, setPhone] = React.useState(contact.phone || "");

  const contactSave = async (contactID, name, value) => {
    setLoading(true);
    await updateContact(contactID, { [name]: value });
    refresh();
    setLoading(false);
  };

  return (
    <Grid item xs={12} className={classes.bio} style={{ marginBottom: 12 }}>
      <Grid container spacing={1} justify="space-between">
        <Grid item>
          <InputBase
            fullWidth
            style={{
              fontWeight: 400,
              fontSize: "0.75rem",
              color: "rgba(155, 155, 155, 1)",
            }}
            value={label}
            className={classes.inputBase}
            placeholder="Label"
            onChange={(e) => setLabel(e.target.value)}
            onBlur={() => contactSave(contact.id, "label", label)}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            label="Primary"
            size="small"
            control={
              <Checkbox
                size="small"
                value={primary}
                checked={primary}
                onChange={(e) => {
                  setPrimary(e.target.checked);
                  contactSave(contact.id, "primary", e.target.checked);
                }}
              />
            }
          />
          <FormControlLabel
            label="Public"
            size="small"
            control={
              <Checkbox
                size="small"
                value={pub}
                checked={pub}
                onChange={(e) => {
                  setPub(e.target.checked);
                  contactSave(contact.id, "public", e.target.checked);
                }}
              />
            }
          />
        </Grid>
      </Grid>
      {category === "ADDRESS" ? (
        <InputBase
          fullWidth
          style={{
            fontWeight: 400,
            fontSize: "1rem",
          }}
          value={street}
          className={classes.inputBase}
          placeholder="Street"
          onChange={(e) => setStreet(e.target.value)}
          onBlur={() => contactSave(contact.id, "street", street)}
        />
      ) : (
        []
      )}
      {category === "ADDRESS" ? (
        <InputBase
          fullWidth
          style={{
            fontWeight: 400,
            fontSize: "1rem",
          }}
          value={zipcode}
          className={classes.inputBase}
          placeholder="Zipcode"
          onChange={(e) => setZipcode(e.target.value)}
          onBlur={() => contactSave(contact.id, "zipcode", zipcode)}
        />
      ) : (
        []
      )}
      {category === "ADDRESS" ? (
        <InputBase
          fullWidth
          style={{
            fontWeight: 400,
            fontSize: "1rem",
          }}
          value={city}
          className={classes.inputBase}
          placeholder="City"
          onChange={(e) => setCity(e.target.value)}
          onBlur={() => contactSave(contact.id, "city", city)}
        />
      ) : (
        []
      )}
      {category === "ADDRESS" ? (
        <InputBase
          fullWidth
          style={{
            fontWeight: 400,
            fontSize: "1rem",
          }}
          value={state}
          className={classes.inputBase}
          placeholder="State"
          onChange={(e) => setState(e.target.value)}
          onBlur={() => contactSave(contact.id, "state", state)}
        />
      ) : (
        []
      )}
      {category === "ADDRESS" ? (
        <InputBase
          fullWidth
          style={{
            fontWeight: 400,
            fontSize: "1rem",
          }}
          value={country}
          className={classes.inputBase}
          placeholder="Country"
          onChange={(e) => setCountry(e.target.value)}
          onBlur={() => contactSave(contact.id, "country", country)}
        />
      ) : (
        []
      )}
      {category === "EMAIL" ? (
        <InputBase
          fullWidth
          style={{
            fontWeight: 400,
            fontSize: "1rem",
          }}
          value={email}
          className={classes.inputBase}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => contactSave(contact.id, "email", email)}
        />
      ) : (
        []
      )}
      {category === "PHONE" ? (
        <InputBase
          fullWidth
          style={{
            fontWeight: 400,
            fontSize: "1rem",
          }}
          value={phone}
          className={classes.inputBase}
          placeholder="Phone"
          onChange={(e) => setPhone(e.target.value)}
          onBlur={() => contactSave(contact.id, "phone", phone)}
        />
      ) : (
        []
      )}
      {category === "LINK" ? (
        <InputBase
          fullWidth
          style={{
            fontWeight: 400,
            fontSize: "1rem",
          }}
          value={email}
          className={classes.inputBase}
          placeholder="Link"
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => contactSave(contact.id, "email", email)}
        />
      ) : (
        []
      )}
      <div style={{ paddingTop: 4 }}>
        <Typography
          variant="caption"
          style={{
            color: "#f44336",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => deleteContact(contact.id).then(refresh)}
        >
          Delete {category.toLowerCase()}
        </Typography>
      </div>
    </Grid>
  );
};

const DynamicRProfile = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [companyName, setCompanyName] = React.useState(
    props.member.companyName || ""
  );
  const [bio, setBio] = React.useState(props.member.bio || "");

  const save = async (name, value) => {
    setLoading(true);
    await props.updateMember(props.member.id, { [name]: value });
    props.refresh();
    setLoading(false);
  };

  const createContact = (category) => {
    props
      .createContact({
        memberID: props.member.id,
        category,
      })
      .then(props.refresh);
  };

  return (
    <Paper className={props.classes.paper}>
      <Grid container spacing={4} direction="column">
        <Grid item>
          {props.loading ? (
            <Skeleton variant="rect" width={147} height={210} />
          ) : (
            <React.Fragment>
              <div
                style={{
                  width: 147,
                  height: 210,
                  backgroundImage: `url(${
                    props.member && props.member.avatar
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <div
                style={{
                  width: 147,
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  Drag and Drop an image to change Avatar or Browse Files.
                </Typography>
              </div>
            </React.Fragment>
          )}
        </Grid>
        <Grid
          item
          style={{
            width: "calc(100% - 190px)",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputBase
                className={props.classes.inputBase}
                fullWidth
                style={{
                  fontWeight: 500,
                  fontSize: "2.125rem",
                }}
                placeholder="Resource Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                onBlur={() => save("companyName", companyName)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Divider />
          <Typography
            style={{
              color: "#8898aa",
            }}
            variant="overline"
          >
            Tags
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TagSelect
                getTags={props.getTags}
                onSelect={(value) => {
                  props
                    .createMemberTag({
                      memberID: props.member.id,
                      tagID: value.value.id,
                    })
                    .then(props.refresh);
                }}
              />
            </Grid>
            {props.member &&
              props.member.tags &&
              props.member.tags.map((i) => (
                <Grid item>
                  <Chip
                    label={i.name}
                    onClick={() =>
                      props
                        .updateMemberTag(i.id, { teaching: !i.teaching })
                        .then(props.refresh)
                    }
                    onDelete={() =>
                      props.deleteMemberTag(i.id).then(props.refresh)
                    }
                  />
                </Grid>
              ))}
          </Grid>
          <br />
          <Divider />
          <Typography
            style={{
              color: "#8898aa",
            }}
            variant="overline"
          >
            Addresses
          </Typography>
          <Grid container spacing={2}>
            {props.contacts.ADDRESS.map((a) => (
              <ContactEdit
                contact={a}
                classes={props.classes}
                contactSave={props.contactSave}
                updateContact={props.updateContact}
                deleteContact={props.deleteContact}
                refresh={props.refresh}
              />
            ))}
            <Grid item xs={12}>
              <Chip
                label={"+ Address"}
                onClick={() => createContact("ADDRESS")}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <br />
          <Divider />
          <Typography
            style={{
              color: "#8898aa",
            }}
            variant="overline"
          >
            Emails
          </Typography>
          <Grid container spacing={2}>
            {props.contacts.EMAIL.map((a) => (
              <ContactEdit
                contact={a}
                classes={props.classes}
                contactSave={props.contactSave}
                updateContact={props.updateContact}
                deleteContact={props.deleteContact}
                refresh={props.refresh}
              />
            ))}
            <Grid item xs={12}>
              <Chip
                label={"+ Email"}
                variant="outlined"
                onClick={() => createContact("EMAIL")}
              />
            </Grid>
          </Grid>
          <br />
          <Divider />
          <Typography
            style={{
              color: "#8898aa",
            }}
            variant="overline"
          >
            Phones
          </Typography>
          <Grid container spacing={2}>
            {props.contacts.PHONE.map((a) => (
              <ContactEdit
                contact={a}
                classes={props.classes}
                contactSave={props.contactSave}
                updateContact={props.updateContact}
                deleteContact={props.deleteContact}
                refresh={props.refresh}
              />
            ))}
            <Grid item xs={12}>
              <Chip
                label={"+ Phone"}
                variant="outlined"
                onClick={() => createContact("PHONE")}
              />
            </Grid>
          </Grid>
          <br />
          <Divider />
          <Typography
            style={{
              color: "#8898aa",
            }}
            variant="overline"
          >
            Links
          </Typography>
          <Grid container spacing={2}>
            {props.contacts.LINK.map((a) => (
              <ContactEdit
                contact={a}
                classes={props.classes}
                contactSave={props.contactSave}
                updateContact={props.updateContact}
                deleteContact={props.deleteContact}
                refresh={props.refresh}
              />
            ))}
            <Grid item xs={12}>
              <Chip
                label={"+ Link"}
                variant="outlined"
                onClick={() => createContact("LINK")}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ProfileActions = ({ member }) => {
  const { contacts } = member;
  const addresses =
    contacts && contacts.filter((c) => c.category === "ADDRESS");
  const phones = contacts && contacts.filter((c) => c.category === "PHONE");
  const emails = contacts && contacts.filter((c) => c.category === "EMAIL");

  return (
    <Grid
      container
      justify="center"
      spacing={2}
      style={{
        marginTop: 12,
        textAlign: "center",
      }}
    >
      {addresses && addresses.length ? (
        <Grid item>
          <Link
            style={{ textDecoration: "none" }}
            href={`https://www.google.com/maps/place/${addresses[0].street}+${addresses[0].city}+${addresses[0].state}+${addresses[0].zipcode}`}
          >
            <Fab
              color="primary"
              style={{
                backgroundcolor: "#8898aa",
              }}
              size="small"
            >
              <NavigationIcon />
            </Fab>
            <br />
            <Typography variant="caption" color="textSecondary">
              Direction
            </Typography>
          </Link>
        </Grid>
      ) : (
        []
      )}
      {emails && emails.length ? (
        <Grid item>
          <Link
            style={{ textDecoration: "none" }}
            href={`mailto:${emails[0].email}`}
          >
            <Fab
              color="primary"
              style={{
                backgroundcolor: "#8898aa",
              }}
              size="small"
            >
              <MailIcon />
            </Fab>
            <br />
            <Typography variant="caption" color="textSecondary">
              Email
            </Typography>
          </Link>
        </Grid>
      ) : (
        []
      )}
      {phones && phones.length ? (
        <Grid item>
          <Link
            style={{ textDecoration: "none" }}
            href={`tel:${phones[0].phone}`}
          >
            <Fab
              color="primary"
              style={{
                backgroundcolor: "#8898aa",
              }}
              size="small"
            >
              <PhoneIcon />
            </Fab>
            <br />
            <Typography variant="caption" color="textSecondary">
              Phone
            </Typography>
          </Link>
        </Grid>
      ) : (
        []
      )}
      {phones && phones.length ? (
        <Grid item>
          <Link
            style={{ textDecoration: "none" }}
            href={`sms:${phones[0].phone}`}
          >
            <Fab
              color="primary"
              style={{
                backgroundcolor: "#8898aa",
              }}
              size="small"
            >
              <TextsmsIcon />
            </Fab>
            <br />
            <Typography variant="caption" color="textSecondary">
              Text
            </Typography>
          </Link>
        </Grid>
      ) : (
        []
      )}
    </Grid>
  );
};

const StaticProfile = (props) => {
  return (
    <React.Fragment>
      {props.admin && props.member ? (
        <Paper
          className={props.classes.paper}
          style={{
            background: "#f44336",
            color: "white",
          }}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="h6"
                color="inherit"
                style={{ paddingBottom: 4 }}
              >
                <AccessibilityNewIcon fontSize="small" color="inherit" /> Admin
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="inherit">
                <b>Only Admins can see this section</b>
              </Typography>
            </Grid>
          </Grid>
          {props.tags ? (
            <Grid container spacing={3}>
              <Grid item>
                <Typography
                  variant="overline"
                  color="inherit"
                  style={{ opacity: 0.7 }}
                >
                  Tags
                </Typography>
                <Grid container spacing={1}>
                  {props.member.tags
                    .filter((t) => t.kind === "Company")
                    .map((t) => (
                      <Grid item key={t.id}>
                        <Chip
                          label={t.name}
                          size="small"
                          color="inherit"
                          style={{ borderColor: "white", color: "white" }}
                          variant="outlined"
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </Paper>
      ) : (
        []
      )}
      <Paper className={props.classes.paper}>
        <Grid container spacing={2} direction="column">
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${props.member && props.member.avatar})`,
              }}
              className={props.classes.avatarOverlay}
            />
            <div className={props.classes.avatarBlurredOverlay} />
            {props.loading ? (
              <Skeleton
                variant="circle"
                height={150}
                width={150}
                style={{ margin: "auto" }}
              />
            ) : (
              <Avatar
                src={props.member && props.member.avatar}
                className={props.classes.avatar}
              />
            )}
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            {props.loading ? (
              <React.Fragment>
                <Skeleton
                  variant="rect"
                  height={20}
                  width={"50%"}
                  style={{ margin: "auto" }}
                />
                <br />
                <Skeleton
                  variant="rect"
                  height={10}
                  width={"30%"}
                  style={{ margin: "auto" }}
                />
                <br />
                <Skeleton variant="rect" height={40} width={"100%"} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="h4" style={{ fontWeight: 500 }}>
                  {props.member.companyName}
                </Typography>
                <Grid
                  container
                  justify="center"
                  spacing={1}
                  style={{ marginTop: 2 }}
                ></Grid>
                {props.member.bio && props.member.bio.length > 1 ? (
                  <div className={props.classes.bio}>{props.member.bio}</div>
                ) : (
                  []
                )}
                <ProfileActions member={props.member} />
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper className={props.classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            {props.loading ? (
              <React.Fragment>
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton width={"20%"} />
                <Skeleton />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="h6" style={{ paddingBottom: 4 }}>
                  <MusicNote fontSize="small" /> Tags
                </Typography>
                <Grid container spacing={1}>
                  {props.member &&
                  props.member.tags &&
                  props.member.tags.length === 0 ? (
                    <Chip label="No Tags" variant="outlined" size="small" />
                  ) : (
                    []
                  )}
                  {props.member &&
                    props.member.tags &&
                    props.member.tags.map((i) => (
                      <Grid item>
                        <Chip label={i.name} />
                      </Grid>
                    ))}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Paper>
      {props.contacts.ADDRESS.length ? (
        <Paper className={props.classes.paper}>
          <Grid container>
            <Grid item>
              {props.contacts.ADDRESS.length && !props.loading ? (
                <React.Fragment>
                  <Typography variant="h6" style={{ paddingBottom: 4 }}>
                    <HomeIcon fontSize="small" /> Addresses
                  </Typography>
                  <Grid container spacing={2}>
                    {props.contacts.ADDRESS.map((a) => (
                      <Grid item xs={12}>
                        <Tag
                          classes={props.classes}
                          a={a}
                          admin={props.admin}
                        ></Tag>
                        <a
                          href={`https://www.google.com/maps/place/${a.street}+${a.zipcode}+${a.city}+${a.state}+${a.country}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          <Typography>{a.street}</Typography>
                          <Typography>{`${a.zipcode} ${a.city}`}</Typography>
                          <Typography>{`${a.state} ${a.country}`}</Typography>
                        </a>
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        []
      )}
      {props.contacts.EMAIL.length ? (
        <Paper className={props.classes.paper}>
          <Grid container>
            <Grid item>
              {props.contacts.EMAIL.length && !props.loading ? (
                <React.Fragment>
                  <Typography variant="h6" style={{ paddingBottom: 4 }}>
                    <EmailIcon fontSize="small" /> Emails
                  </Typography>
                  <Grid container spacing={2}>
                    {props.contacts.EMAIL.map((a) => (
                      <Grid item xs={12}>
                        {
                          <React.Fragment>
                            <Tag
                              classes={props.classes}
                              a={a}
                              admin={props.admin}
                            ></Tag>
                            <a
                              href={`mailto:${a.email}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {a.email}
                            </a>
                          </React.Fragment>
                        }
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        []
      )}
      {props.contacts.PHONE.length ? (
        <Paper className={props.classes.paper}>
          <Grid container>
            <Grid item>
              {props.contacts.PHONE.length && !props.loading ? (
                <React.Fragment>
                  <Typography variant="h6" style={{ paddingBottom: 4 }}>
                    <SmartphoneIcon fontSize="small" /> Phones
                  </Typography>
                  <Grid container spacing={2}>
                    {props.contacts.PHONE.map((a) => (
                      <Grid item xs={12}>
                        {
                          <React.Fragment>
                            <Tag
                              classes={props.classes}
                              a={a}
                              admin={props.admin}
                            ></Tag>
                            <a
                              href={`tel:${a.phone}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {a.phone}
                            </a>
                          </React.Fragment>
                        }
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        []
      )}
      {props.contacts.LINK.length ? (
        <Paper className={props.classes.paper}>
          <Grid container>
            <Grid item>
              {props.contacts.LINK.length && !props.loading ? (
                <React.Fragment>
                  <Typography variant="h6" style={{ paddingBottom: 4 }}>
                    <LinkIcon fontSize="small" /> Links
                  </Typography>
                  <Grid container spacing={2}>
                    {props.contacts.LINK.map((a) => (
                      <Grid item xs={12}>
                        {
                          <React.Fragment>
                            <Tag
                              classes={props.classes}
                              a={a}
                              admin={props.admin}
                            ></Tag>
                            <a
                              href={
                                a.email.indexOf("http") !== -1
                                  ? a.email
                                  : `https://${a.email}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {a.email}
                            </a>
                          </React.Fragment>
                        }
                      </Grid>
                    ))}
                  </Grid>
                </React.Fragment>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Paper>
      ) : (
        []
      )}
    </React.Fragment>
  );
};

const DynamicProfile = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [companyName, setCompanyName] = React.useState(
    props.member.companyName || ""
  );
  const [bio, setBio] = React.useState(props.member.bio || "");
  const [goodStanding, setGoodStanding] = React.useState(
    props.member.goodStanding
  );
  const [subscriber, setSubscriber] = React.useState(props.member.subscriber);
  const [pub, setPub] = React.useState(props.member.public);
  const [notes, setNotes] = React.useState(props.member.notes);
  const [comments, setComments] = React.useState(props.member.comments);
  const [tagAnchorEl, setTagAnchorEl] = React.useState(null);
  const [unionAnchorEl, setUnionAnchorEl] = React.useState(null);

  const save = async (name, value) => {
    setLoading(true);
    await props.updateMember(props.member.id, { [name]: value });
    props.refresh();
    setLoading(false);
  };

  const createContact = (category) => {
    props
      .createContact({
        memberID: props.member.id,
        category,
      })
      .then(props.refresh);
  };

  return (
    <React.Fragment>
      {props.admin && props.member ? (
        <Paper
          className={props.classes.paper}
          style={{
            background: "#f44336",
            color: "white",
          }}
        >
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="h6"
                color="inherit"
                style={{ paddingBottom: 4 }}
              >
                <AccessibilityNewIcon fontSize="small" color="inherit" /> Admin
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="inherit">
                <b>Only Admins can see this section</b>
              </Typography>
            </Grid>
          </Grid>
          {props.tags ? (
            <Grid container spacing={3}>
              <Grid item>
                <Typography
                  variant="overline"
                  color="inherit"
                  style={{ opacity: 0.7 }}
                >
                  Tags
                </Typography>
                <Grid container spacing={1}>
                  {props.member.tags &&
                    props.member.tags.map((t) => (
                      <Grid item key={t.id}>
                        <Chip
                          label={t.name}
                          size="small"
                          onDelete={() =>
                            props
                              .deleteMemberTag(t.relationID)
                              .then(props.refresh)
                          }
                          style={{ borderColor: "white", color: "#f44336" }}
                        />
                      </Grid>
                    ))}
                  <Grid item>
                    <Chip
                      label="+ Add Tag"
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={(e) => setTagAnchorEl(e.currentTarget)}
                      style={{ borderColor: "white", color: "white" }}
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={tagAnchorEl}
                      keepMounted
                      open={Boolean(tagAnchorEl)}
                      onClose={() => setTagAnchorEl(null)}
                    >
                      {props.tags &&
                        props.tags
                          .filter((t) => t.kind === "company")
                          .map((t) => (
                            <MenuItem
                              key={t.id}
                              onClick={() =>
                                props
                                  .createMemberTag({
                                    memberID: props.member.id,
                                    tagID: t.id,
                                  })
                                  .then(() => {
                                    props.refresh();
                                    setTagAnchorEl(null);
                                  })
                              }
                            >
                              {t.name}
                            </MenuItem>
                          ))}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            []
          )}
          {props.member.unions ? (
            <Grid container spacing={3}>
              <Grid item>
                <Typography
                  variant="overline"
                  color="inherit"
                  style={{ opacity: 0.7 }}
                >
                  Unions
                </Typography>
                <Grid container spacing={1}>
                  {props.member.unions.map((t) => (
                    <Grid item>
                      <Chip
                        label={t.name}
                        size="small"
                        onDelete={() =>
                          props
                            .deleteMemberUnion(t.relationID)
                            .then(props.refresh)
                        }
                        style={{ borderColor: "white", color: "#f44336" }}
                      />
                    </Grid>
                  ))}
                  <Grid item>
                    <Chip
                      label="+ Add Union"
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={(e) => setUnionAnchorEl(e.currentTarget)}
                      style={{ borderColor: "white", color: "white" }}
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={unionAnchorEl}
                      keepMounted
                      open={Boolean(unionAnchorEl)}
                      onClose={() => setUnionAnchorEl(null)}
                    >
                      {props.unions &&
                        props.unions.map((t) => (
                          <MenuItem
                            key={t.id}
                            onClick={() =>
                              props
                                .createMemberUnion({
                                  memberID: props.member.id,
                                  unionID: t.id,
                                })
                                .then(() => {
                                  props.refresh();
                                  setUnionAnchorEl(null);
                                })
                            }
                          >
                            {t.name}
                          </MenuItem>
                        ))}
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            []
          )}
        </Paper>
      ) : (
        []
      )}
      <Paper className={props.classes.paper}>
        <Grid container spacing={2} direction="column">
          <Grid
            item
            xs={12}
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${props.member && props.member.avatar})`,
              }}
              className={props.classes.avatarOverlay}
            />
            <div className={props.classes.avatarBlurredOverlay} />
            {props.loading ? (
              <Skeleton
                variant="circle"
                height={150}
                width={150}
                style={{ margin: "auto" }}
              />
            ) : (
              <Avatar
                src={props.member && props.member.avatar}
                className={props.classes.avatar}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography variant="caption" color="textSecondary">
              Drag and drop an image to change the avatar
            </Typography>
          </Grid>
          <Grid item style={{ textAlign: "center" }}>
            {props.loading ? (
              <React.Fragment>
                <Skeleton
                  variant="rect"
                  height={20}
                  width={"50%"}
                  style={{ margin: "auto" }}
                />
                <br />
                <Skeleton
                  variant="rect"
                  height={10}
                  width={"30%"}
                  style={{ margin: "auto" }}
                />
                <br />
                <Skeleton variant="rect" height={40} width={"100%"} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <InputBase
                      className={props.classes.inputBase}
                      fullWidth
                      style={{
                        fontWeight: 500,
                        fontSize: "2.125rem",
                      }}
                      placeholder="Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      onBlur={() => save("companyName", companyName)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      fullWidth
                      className={`${props.classes.inputBase} ${props.classes.bio}`}
                      style={{
                        padding: 20,
                      }}
                      required
                      placeholder="Bio"
                      value={bio}
                      multiline
                      onBlur={() => save("bio", bio)}
                      onChange={(e) => setBio(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper className={props.classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            {props.loading ? (
              <React.Fragment>
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton width={"20%"} />
                <Skeleton />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography variant="h6" style={{ paddingBottom: 4 }}>
                  <MusicNote fontSize="small" /> Tags
                </Typography>
                <Grid container spacing={1}>
                  {props.member &&
                  props.member.memberTags &&
                  props.member.memberTags.length === 0 ? (
                    <Grid item>
                      <Chip label="No Tags" variant="outlined" size="small" />
                    </Grid>
                  ) : (
                    []
                  )}
                  <Grid item xs={12}>
                    <TagSelect
                      getTags={props.getTags}
                      onSelect={(value) => {
                        props
                          .createMemberTag({
                            memberID: props.member.id,
                            tagID: value.value.id,
                          })
                          .then(props.refresh);
                      }}
                    />
                  </Grid>
                  {props.member &&
                    props.member.memberTags &&
                    props.member.memberTags.map((i) => (
                      <Grid item>
                        <Chip
                          label={i.name}
                          onClick={() =>
                            props
                              .updateMemberTag(i.id, { teaching: !i.teaching })
                              .then(props.refresh)
                          }
                          onDelete={() =>
                            props.deleteMemberTag(i.id).then(props.refresh)
                          }
                        />
                      </Grid>
                    ))}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper className={props.classes.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" style={{ paddingBottom: 4 }}>
              <HomeIcon fontSize="small" /> Addresses
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label={"+ Address"}
              onClick={() => createContact("ADDRESS")}
              variant="outlined"
            />
          </Grid>
          {props.contacts.ADDRESS.map((a) => (
            <ContactEdit
              contact={a}
              classes={props.classes}
              contactSave={props.contactSave}
              updateContact={props.updateContact}
              deleteContact={props.deleteContact}
              refresh={props.refresh}
            />
          ))}
        </Grid>
      </Paper>
      <Paper className={props.classes.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" style={{ paddingBottom: 4 }}>
              <EmailIcon fontSize="small" /> Emails
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label={"+ Email"}
              onClick={() => createContact("EMAIL")}
              variant="outlined"
            />
          </Grid>
          {props.contacts.EMAIL.map((a) => (
            <ContactEdit
              contact={a}
              classes={props.classes}
              contactSave={props.contactSave}
              updateContact={props.updateContact}
              deleteContact={props.deleteContact}
              refresh={props.refresh}
            />
          ))}
        </Grid>
      </Paper>
      <Paper className={props.classes.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" style={{ paddingBottom: 4 }}>
              <SmartphoneIcon fontSize="small" /> Phones
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label={"+ Phone"}
              onClick={() => createContact("PHONE")}
              variant="outlined"
            />
          </Grid>
          {props.contacts.PHONE.map((a) => (
            <ContactEdit
              contact={a}
              classes={props.classes}
              contactSave={props.contactSave}
              updateContact={props.updateContact}
              deleteContact={props.deleteContact}
              refresh={props.refresh}
            />
          ))}
        </Grid>
      </Paper>
      <Paper className={props.classes.paper}>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h6" style={{ paddingBottom: 4 }}>
              <SmartphoneIcon fontSize="small" /> Links
            </Typography>
          </Grid>
          <Grid item>
            <Chip
              label={"+ Link"}
              onClick={() => createContact("LINK")}
              variant="outlined"
            />
          </Grid>
          {props.contacts.LINK.map((a) => (
            <ContactEdit
              contact={a}
              classes={props.classes}
              contactSave={props.contactSave}
              updateContact={props.updateContact}
              deleteContact={props.deleteContact}
              refresh={props.refresh}
            />
          ))}
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

class WrapperCompanyPage extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    urlParams: PropTypes.object,
    member: PropTypes.object,
    loading: PropTypes.bool,
    history: PropTypes.object,
    admin: PropTypes.bool,
    refreshKey: PropTypes.number,

    refresh: PropTypes.func,
    updateMember: PropTypes.func,

    updateContact: PropTypes.func,
    deleteContact: PropTypes.func,
    createContact: PropTypes.func,

    tags: PropTypes.array,
    unions: PropTypes.array,
    getTags: PropTypes.func,
    getUnions: PropTypes.func,

    createMemberTag: PropTypes.func,
    deleteMemberTag: PropTypes.func,

    createMemberUnion: PropTypes.func,
    deleteMemberUnion: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    const { UploadCenter } = this.context;
    UploadCenter.register(
      () => console.log("upload"),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );

    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      editMode: false,
    };
  }

  userDidDrop(files) {
    const { editMode } = this.state;
    if (editMode) {
      this.setState({
        files,
        pending: files[0],
        avatarScaleOpen: true,
      });
    }
  }

  async upload() {
    const { updateMember, refresh, member } = this.props;
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        this.setState({
          imageLoading: true,
        });
        UploadCenter.upload(file).then((r) => {
          const avatar = r.payload;
          updateMember(member.id, {
            avatar,
          }).then(refresh);
          this.setState({
            avatar: r.payload,
            imageLoading: false,
          });
        });
      }
    }
  }

  getContacts() {
    const { member, admin } = this.props;
    const contacts = {
      ADDRESS: [],
      EMAIL: [],
      PHONE: [],
      LINK: [],
    };
    if (member && member.contacts) {
      for (const key in member.contacts) {
        if (member.contacts.hasOwnProperty(key)) {
          const contact = member.contacts[key];
          if (contact.public) {
            contacts[contact.category].push(contact);
          } else if (admin) {
            contacts[contact.category].push(contact);
          }
        }
      }
    }
    return contacts;
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: `Are you sure ?`,
        subtitle: "If you delete this member, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteMember, member, history } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await deleteMember(member.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Member has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      history.push(COMPANIES);
    }
  }

  render() {
    const {
      classes,
      loading,
      member,
      history,
      refreshKey,
      refresh,
      updateMember,
      updateContact,
      deleteContact,
      createContact,
      getTags,
      admin,
      urlParams,
      tags,
      unions,
      createMemberTag,
      deleteMemberTag,
      createMemberUnion,
      deleteMemberUnion,
    } = this.props;

    const { editMode, pending, avatarScaleOpen } = this.state;

    const contacts = this.getContacts();

    return (
      <Page
        helmet={loading ? "Loading" : `${member && member.companyName}`}
        loading={loading}
      >
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: 600,
            width: "100%",
            position: "relative",
          }}
        >
          {!urlParams.readOnly ? (
            <Grid container justify="space-between">
              <Grid item>
                <Button
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  size="small"
                  onClick={() => history.push(COMPANIES)}
                  startIcon={<Back />}
                >
                  Back
                </Button>
              </Grid>
              <Grid item>
                {/* <IconButton aria-label="download">
                    <Download fontSize="small" />
                  </IconButton>
                  <IconButton aria-label="share">
                    <ShareIcon fontSize="small" />
                  </IconButton> */}
                {admin ? (
                  <React.Fragment>
                    <Button
                      variant="outlined"
                      style={{ textTransform: "none", marginRight: 16 }}
                      size="small"
                      onClick={this.delete.bind(this)}
                      startIcon={<Close />}
                    >
                      Delete Resource
                    </Button>
                    {!editMode ? (
                      <Button
                        variant="outlined"
                        style={{ textTransform: "none" }}
                        size="small"
                        onClick={() =>
                          this.setState((prevState) => ({
                            editMode: !prevState.editMode,
                          }))
                        }
                        startIcon={<Edit />}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        style={{
                          textTransform: "none",
                          background: "#4caf50",
                          color: "white",
                        }}
                        size="small"
                        onClick={() =>
                          this.setState((prevState) => ({
                            editMode: !prevState.editMode,
                          }))
                        }
                        startIcon={<CheckCircleOutlineIcon />}
                      >
                        Done
                      </Button>
                    )}
                  </React.Fragment>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          ) : (
            []
          )}
          {editMode ? (
            <DynamicProfile
              // key={refreshKey}
              classes={classes}
              loading={loading}
              member={member}
              admin={admin}
              updateMember={updateMember}
              refresh={refresh}
              contacts={contacts}
              save={(name, value) => console.log(name, value)}
              contactSave={(id, name, value) => console.log(id, name, value)}
              updateContact={updateContact}
              deleteContact={deleteContact}
              createContact={createContact}
              getTags={getTags}
              tags={tags}
              unions={unions}
              createMemberTag={createMemberTag}
              deleteMemberTag={deleteMemberTag}
              createMemberUnion={createMemberUnion}
              deleteMemberUnion={deleteMemberUnion}
            />
          ) : (
            <StaticProfile
              key={refreshKey}
              classes={classes}
              loading={loading}
              member={member}
              admin={admin}
              contacts={contacts}
            />
          )}
          <AvatarScale
            open={avatarScaleOpen}
            close={() => this.setState({ avatarScaleOpen: false })}
            onSave={(b) => {
              this.setState({ files: [b] }, () => {
                this.upload();
              });
            }}
            onSave={(a) => {
              this.setState({ files: [a] }, () => {
                this.upload();
              });
            }}
            image={pending && pending.preview}
          />
        </div>
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperCompanyPage);
