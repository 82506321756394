import { networkAction } from "helpers/network/networkAction";

import {
  GET_MEMBER_UNIONS,
  GET_MEMBER_UNION_BY_ID,
  DELETE_MEMBER_UNION,
  UPDATE_MEMBER_UNION,
  CREATE_MEMBER_UNION,
} from "constants/memberUnions";

import * as Api from "api";

export const getMemberUnions = () => async (dispatch) =>
  networkAction(dispatch, GET_MEMBER_UNIONS, Api.getMemberUnions, []);

export const getMemberUnionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_MEMBER_UNION_BY_ID, Api.getMemberUnionById, [id]);

export const deleteMemberUnion = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_MEMBER_UNION, Api.deleteMemberUnion, [id]);

export const createMemberUnion = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_MEMBER_UNION, Api.createMemberUnion, [body]);

export const updateMemberUnion = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_MEMBER_UNION, Api.updateMemberUnion, [
    id,
    body,
  ]);
