import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Route, Switch, withRouter } from "react-router-dom";
import ReactGA from "react-ga";

import { DEFAULT, SIGNIN } from "constants/routes";

import { menu } from "config/menu";
import Signup from "components/Signup";
import NotificationCenter from "components/NotificationCenter";
import Upload from "components/Upload";
import ForgotPassword from "components/ForgotPassword";
import ResetPassword from "components/ResetPassword";
import KeyboardEvents from "components/KeyboardEvents";
import UrlActions from "components/UrlActions";
import EnableCookieMessage from "components/EnableCookieMessage";
import { withCookies } from "react-cookie";

// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import classNames from "classnames";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Fingerprint from "@material-ui/icons/Fingerprint";
import Collapse from "@material-ui/core/Collapse";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Left from "@material-ui/icons/KeyboardArrowLeft";
import Right from "@material-ui/icons/KeyboardArrowRight";

// reactor
import AuthChecker from "components/AuthChecker";
import MessageDrawer from "components/MessageDrawer";
import EnvMessage from "components/EnvMessage";
import LeftMenu from "components/LeftMenu";
import Centrifugo from "components/Centrifugo";

// custom
import AppBar from "components/AppBar";

// helpers
import { redirectAccordingToRole } from "helpers/redirect";

import {
  GOOGLE_ANALYTICS_KEY,
  ENV_MESSAGE,
  REACT_APP_ROOT_BACKGROUND,
} from "config";

// styles
import styles from "./styles";

class WrapperRootPage extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    history: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,
    theme: PropTypes.object,
    allRoutes: PropTypes.array,
    cookies: PropTypes.any,

    // Reducers
    user: PropTypes.object,
    userApps: PropTypes.array,

    // Api
    getCurrentUser: PropTypes.func,
    websocketMessageReceived: PropTypes.func,
    init: PropTypes.func,
    signout: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
    upload: PropTypes.func,
    bazaarCustomer: PropTypes.object,
    subscriptions: PropTypes.array,

    // errors
    errors: PropTypes.object,

    // app
    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,
    app: PropTypes.object,
    stopImpersonate: PropTypes.func,
  };

  static childContextTypes = {
    appBarMenuHook: PropTypes.func,
    onAppLogoClickedHook: PropTypes.func,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      mobileOpen: false,
      anchorEl: null,
      accountOpen: false,
      loading: true,
      alert: null,
      vh: 0,
    };

    if (GOOGLE_ANALYTICS_KEY) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    window.addEventListener("resize", this.refreshInnerHeight.bind(this));
  }

  getChildContext() {
    return {
      appBarMenuHook: (e) => this.setState({ appBarMenuHook: e }),
      onAppLogoClickedHook: (e) => this.setState({ onAppLogoClickedHook: e }),
    };
  }

  componentDidMount() {
    this.refreshInnerHeight();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      if (GOOGLE_ANALYTICS_KEY) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  refreshInnerHeight() {
    // We execute the same script as before
    console.log("refreshInnerHeight");

    const vh = window.innerHeight * 0.01;
    this.setState({ vh });
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  injectRoutes() {
    const { routes } = this.props;

    return (
      <div style={{ flexGrow: 1, display: "flex", position: "relative" }}>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    );
  }

  render() {
    const {
      classes,
      location,
      routes,
      history,
      getCurrentUser,
      init,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      signout,
      storeResetPasswordToken,
      app,
      resetPassword,
      allRoutes,
      user,
      theme,
      setDarkMode,
      stopImpersonate,
      userApps,
      setOrganizationByDefault,
      websocketMessageReceived,
      joinOrganization,
      bazaarCustomer,
      subscriptions,
      upload,
    } = this.props;

    const { loading, alert, mobileOpen } = this.state;

    const route = routes.find((r) => r.path === location.pathname);

    return (
      <div className="root">
        <AuthChecker
          routes={allRoutes}
          history={history}
          location={location}
          user={user}
          getCurrentUser={getCurrentUser}
          onReady={() => {
            this.setState({ loading: false });
          }}
          signinRoute={SIGNIN}
          redirectAccordingToRole={redirectAccordingToRole}
          init={init}
        >
          <NotificationCenter errors={errors}>
            <Centrifugo websocketMessageReceived={websocketMessageReceived}>
              <KeyboardEvents>
                <Signup
                  history={history}
                  signup={signup}
                  validateEmail={validateEmail}
                >
                  <UrlActions
                    location={location}
                    pathname={`${location.pathname}`}
                    history={history}
                    validateEmail={validateEmail}
                    user={user}
                    loading={loading}
                    storeResetPasswordToken={storeResetPasswordToken}
                  >
                    <ForgotPassword
                      history={history}
                      requestResetPassword={requestResetPassword}
                    >
                      <ResetPassword
                        token={app.resetPasswordToken}
                        resetPassword={resetPassword}
                        storeResetPasswordToken={storeResetPasswordToken}
                      >
                        <Upload upload={upload}>
                          {loading ? (
                            <Grid
                              container
                              className={classes.loading}
                              alignContent="center"
                              alignItems="center"
                            >
                              <Grid
                                item
                                xs={12}
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                <CircularProgress
                                  style={{ color: "#ffffff" }}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <MessageDrawer
                              background={REACT_APP_ROOT_BACKGROUND}
                            >
                              <EnvMessage envMessage={ENV_MESSAGE} />
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                }}
                              >
                                {route && route.withSidebar && (
                                  <LeftMenu
                                    routes={routes}
                                    handleDrawerToggle={this.handleDrawerToggle.bind(
                                      this
                                    )}
                                    mobileOpen={mobileOpen}
                                    history={history}
                                    user={user}
                                    location={location}
                                  />
                                )}
                                <div
                                  style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {route && route.withAppBar && (
                                    <AppBar
                                      onMenuOpen={() => {
                                        this.handleDrawerToggle();
                                        if (this.state.appBarMenuHook) {
                                          this.state.appBarMenuHook();
                                        }
                                      }}
                                      onAppLogoClicked={() => {
                                        if (this.state.onAppLogoClickedHook) {
                                          this.state.onAppLogoClickedHook();
                                        }
                                      }}
                                      user={user}
                                      userApps={userApps}
                                      history={history}
                                      app={app}
                                      bazaarCustomer={bazaarCustomer}
                                      subscriptions={subscriptions}
                                      setDarkMode={setDarkMode}
                                      stopImpersonate={stopImpersonate}
                                      setOrganizationByDefault={
                                        setOrganizationByDefault
                                      }
                                      joinOrganization={joinOrganization}
                                      getCurrentUser={getCurrentUser}
                                      signout={signout}
                                    />
                                  )}
                                  {this.injectRoutes()}
                                  {alert}
                                </div>
                              </div>
                            </MessageDrawer>
                          )}
                        </Upload>
                      </ResetPassword>
                    </ForgotPassword>
                  </UrlActions>
                </Signup>
              </KeyboardEvents>
            </Centrifugo>
          </NotificationCenter>
        </AuthChecker>
        <EnableCookieMessage
          open={!navigator.cookieEnabled}
          close={() => this.setState({ enableCookie: false })}
        />
      </div>
    );
  }
}

export default withCookies(
  withRouter(
    connect()(withStyles(styles, { withTheme: true })(WrapperRootPage))
  )
);
