import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import FormMember from "components/FormMember";
// components
import MembersList from "components/MembersList";
import Page from "components/Page";
// config
import { REACT_APP_CLIENT_NAME, REACT_APP_SEARCH_BACKGROUND } from "config";
// helpers
import { getJsonFromUrl } from "helpers";
// hooks
import SearchBox from "hooks/SearchBox";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperMembersPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,
    admin: PropTypes.bool,

    // instruments
    instruments: PropTypes.array,

    //members
    members: PropTypes.array,
    getMemberById: PropTypes.func,
    getMembers: PropTypes.func,
    createMember: PropTypes.func,
    deleteMember: PropTypes.func,
    updateMember: PropTypes.func,
    subscriptions: PropTypes.array,
  };

  static contextTypes = {
    getPageContainer: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedMember: undefined,
      formMemberOpen: false,
      miniHeader: false,
      memberOpen: false,
      name: "",
      instrument: "",
    };
  }

  async onSearch(name, instrument) {
    const { history, getMembers } = this.props;
    const filters = [];
    const urlParams = getJsonFromUrl(window.location);

    delete urlParams[""];
    if (name) {
      filters.push({
        name: "name",
        comparison: "like",
        value: name,
      });
    }
    if (instrument) {
      filters.push({
        name: "instrumentID",
        comparison: "eq",
        value: instrument.id,
      });
    }
    this.setState({ name, instrument, loading: true });
    urlParams.filters = JSON.stringify(filters);
    const search = Object.keys(urlParams)
      .map(
        (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
      )
      .join("&");
    history.push({
      search,
    });
    await getMembers(filters);
    this.setState({ loading: false });
  }

  getStepContent() {
    const { index, loading } = this.state;
    const {
      members,
      classes,
      history,
      instruments,
      subscriptions,
      admin,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <div className={classes.header}>
            <div
              className={classes.headerSearch}
              style={{
                backgroundImage: `url(${REACT_APP_SEARCH_BACKGROUND})`,
              }}
            >
              <Grid
                container
                alignContent="center"
                style={{
                  height: "100%",
                  maxWidth: 1500,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Grid item xs={12} md={6}>
                  <div style={{ margin: 20, maxWidth: 600 }}>
                    <Typography
                      variant="h3"
                      style={{
                        color: "white",
                        textShadow: "3px 3px 3px rgba(0,0,0,0.3)",
                      }}
                    >
                      {`${REACT_APP_CLIENT_NAME} Public Directory`}
                    </Typography>
                  </div>
                  <SearchBox
                    onSearch={this.onSearch.bind(this)}
                    instruments={instruments}
                  />
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                padding: 20,
                textAlign: loading ? "center" : undefined,
              }}
            >
              {admin ? (
                <Chip
                  onClick={() => this.setState({ memberOpen: true })}
                  label="+ Add Member"
                  style={{ marginBottom: 16 }}
                  variant="outlined"
                />
              ) : (
                []
              )}
              {loading ? (
                <CircularProgress />
              ) : (
                <MembersList
                  members={members}
                  subscriptions={subscriptions}
                  history={history}
                  onSelect={(selectedMember) =>
                    this.setState({
                      selectedMember,
                      formMemberOpen: true,
                    })
                  }
                />
              )}
            </div>
          </div>
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createMember,
      deleteMember,
      updateMember,
      refresh,
    } = this.props;

    const {
      index,
      selectedMember,
      formMemberOpen,
      memberOpen,
      name,
      instrument,
    } = this.state;

    const { getPageContainer } = this.context;

    return (
      <Page
        helmet={"Members"}
        loadingMessage={"Loading Document"}
        loading={loading}
        noPadding
        fabFunc={(page) => (
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => {
              page.current.scrollTo({ top: 0 });
            }}
          >
            <KeyboardArrowUpIcon />
          </Fab>
        )}
      >
        {this.getStepContent()}
        <FormMember
          open={memberOpen}
          history={history}
          close={() => this.setState({ memberOpen: false })}
          createMember={createMember}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMembersPage);
